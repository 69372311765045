import React from "react"

import "./index.css"

export default class Wheel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: this.props.init,
    }
    this.selectItem = this.selectItem.bind(this)
  }

  selectItem() {
    var spin = localStorage.getItem("spin")
    if (spin == "true") {
      alert("Sorry, you already use your spin.")
    } else {
      if (this.state.selectedItem === null) {
        const selectedItem = Math.floor(Math.random() * this.props.items.length)
        if (this.props.onSelectItem) {
          this.props.onSelectItem(selectedItem)
        }
        this.setState({
          selectedItem: selectedItem == 2 ? selectedItem - 1 : selectedItem,
        })

        if (selectedItem == 7 || selectedItem == 9) {
          localStorage.setItem("spin", false)
        } else {
          localStorage.setItem("spin", true)
        }
      } else {
        this.setState({ selectedItem: null })
        setTimeout(this.selectItem, 500)
      }
      this.props.getPrize(this.state.selectedItem)
    }
  }

  render() {
    const { selectedItem } = this.state
    const { items } = this.props

    const wheelVars = {
      "--nb-item": items.length,
      "--selected-item": selectedItem,
    }
    const spinning = selectedItem !== null ? "spinning" : ""

    return (
      <div className="wheel-container">
        <div
          className={`wheel ${spinning}`}
          style={wheelVars}
          onClick={this.selectItem}
        >
          {items.map((item, index) => (
            <div
              className="wheel-item"
              key={index}
              style={{ "--item-nb": index }}
            >
              {item}
            </div>
          ))}
          {this.state.selectItem}
        </div>
      </div>
    )
  }
}
