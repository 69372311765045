import React from "react"
import { Link, withPrefix } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import Reviews from "../components/reviews"
import CTA from "../components/cta"
import Faq from "../components/faq"
import HowItWorks from "../components/how-it-works"
import OptInForm from "../components/optinform"
import { changeProgressBarOnScroll } from "../components/utils/index"
import Slider from "react-slick"
import Wheel from "../components/wheels"
//import ModalBox from '../components/modalbox'

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      prize: 0,
      sidebarWidth: "0px",
      spin: false,
      codes: [
        "SPIN10OFF",
        "SPINWHEEL20OFF",
        "GRANDSPIN100",
        "SPIN10OFF",
        "LUCKYSPIN50",
        "SPIN10OFF",
        "SPINWHEEL20OFF",
        "Try again",
        "SPIN30OFF",
        "SORRY",
        "SPINWHEEL20OFF",
        "SPINWHEEL20OFF",
      ],
    }
    this.places = [
      "RM10 OFF",
      "RM20 OFF",
      "RM100 OFF",
      "RM10 OFF",
      "RM50 OFF",
      "RM10 OFF",
      "RM20 OFF",
      "Try again",
      "RM30 OFF",
      "Sorry !",
      "RM20 OFF",
      "RM20 OFF",
    ]

    this.getPrize = this.getPrize.bind(this)
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
    this.onSetSidebarClose = this.onSetSidebarClose.bind(this)
  }

  getPrize(prize) {
    this.setState({ prize: prize })
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarWidth: "450px" })
  }

  onSetSidebarClose(close) {
    this.setState({ sidebarWidth: "0px" })
  }

  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    this.setState({ spin: localStorage.getItem("spin") })
    window.analytics.page("Static", "Home")
  }

  render() {
    const { opt_in_form, metadata, opt_wheels_form } = pageData
    const googleLdJson = {
      "@context": "http://schema.org",
      "@type": "Organization",
      url: "https://www.advanxhealth.com",
      logo: "https://www.advanxhealth.com/img/logo.png",
      sameAs: [
        "https://www.facebook.com/advanxhealthmy",
        "https://www.instagram.com/advanxhealth/",
        "https://www.linkedin.com/company/advanx-health/",
      ],
    }
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 5000,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    }
    return (
      <Layout>
        <div id="home">
          <HeadMeta metadata={metadata} />
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(googleLdJson)}
            </script>
            <meta
              name="google-site-verification"
              content="lawz8Kryf0LvmHPHVWl6DaIpQtF99rUvmhUgP-mDW6Q"
            />
          </Helmet>
          <ProgressBar />
          <Slider {...settings}>
            {/* <div>
              <section
                className="hero hero-landing bg-gray"
                style={{
                  backgroundPosition: "cover",
                  backgroundColor: "#EBFCFF",
                }}
              >
                <div className="container pb-md-0">
                  <div className="row text-center text-md-left align-items-center">
                    <div className="col-md-7 order-md-2">
                      <img
                        id="homepage-text"
                        src='https://advanxweb.s3.ap-southeast-1.amazonaws.com/covid19/COVID19_Kit.png'
                        alt="GMATE Covid-19 Saliva Test Kit"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-5 text-center order-md-1">
                      <h3 className="mb-3">Gmate COVID-19 Saliva Antigen Test Kit</h3>
                      <div className='h5 font-weight-normal'>
                        An easy-to-home COVID-19 self test kit, results within 15 minutes. Starts at RM36.90!
                      </div>
                      <Link
                        to={withPrefix("/covid-19-rapid-test")}
                        className="btn btn-primary px-5 mt-3"
                      >
                        <strong>Find Out More</strong>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </div> */}
            <div>
              <section className="hero hero-landing" id="dna-explorer-onco" style={{backgroundPosition: 'center'}}>
                  <div className="container">
                    <div className="row d-flex">
                      <div className="col-lg-6 offset-lg-6 text-center text-md-right order-2 order-lg-1 p-4 content">
                        <img
                          className="img-fluid mb-5 d-inline"
                          src={withPrefix("img/logo/DNA-Explorer-Onco.png")}
                          style={{ maxWidth: "200px" }}
                          alt="DNA Explorer Carrier"
                        />
                        <h1 className='h2'>Discover Your Genetic<br/> Risks for Cancer</h1>
                        <p className='h5 font-weight-normal'>
                          Preventive care starts from <br/> understanding your genes
                        </p>
                        <Link
                          to={withPrefix("/products/dna-explorer-onco")}
                          className="btn btn-explorer-green mt-4"
                        >
                          Find Out More
                        </Link>
                      </div>
                    </div>
                  </div>
              </section>
            </div>
            <div>
              <section className="hero hero-landing" id="covidna-banner" style={{backgroundPosition: 'center'}}>
                <div className="container">
                  <div className="row my-lg-5">
                    <div className="col-lg-4 offset-lg-8 text-center content py-4">
                      <img src={withPrefix('img/covidna/covidna-title.png')} className="img-fluid" alt="CoviDNA" style={{maxHeight: '200px'}}/>
                      <Link
                        to={withPrefix("/covidna")}
                        className="btn btn-primary px-5 mt-5"
                      >
                        <strong>Learn More</strong>
                      </Link>
                    </div>  
                  </div>
                </div>
              </section>
            </div>
            <div>
              <section
                className="hero hero-landing bg-gray"
                style={{
                  backgroundPosition: "cover",
                  backgroundColor: "#EBFCFF",
                }}
              >
                <div className="container pb-md-0">
                  <div className="row text-center text-md-left align-items-center">
                    <div className="col-md-6">
                      <img
                        id="homepage-text"
                        src={withPrefix("/img/product-box.png")}
                        alt="Product"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-6 text-center">
                      <h2 className="mb-3">You are unique</h2>
                      <div className='h6 font-weight-normal'>
                        Unlock The secrets of your DNA today.
                        <br />
                        Say goodbye to one-size-fits-all action plans and commit
                        to health goals that are based on your genes with:
                      </div>
                      <div className="row">
                        <Link
                          className="col-md-4 col-6 my-2"
                          to={withPrefix("/products/dna-explorer-prime")}
                        >
                          <img
                            className="img-fluid"
                            src={withPrefix("img/logo/DNA-Explorer-Prime.png")}
                          />
                        </Link>
                        <Link
                          className="col-md-4 col-6 my-2"
                          to={withPrefix("/products/dna-explorer-carrier")}
                        >
                          <img
                            className="img-fluid"
                            src={withPrefix(
                              "img/logo/DNA-Explorer-Carrier.png"
                            )}
                          />
                        </Link>
                        <Link
                          className="col-md-4 offset-md-0 col-6 offset-3 my-2"
                          to={withPrefix("/products/dna-explorer-personal")}
                        >
                          <img
                            className="img-fluid"
                            src={withPrefix(
                              "img/logo/DNA-Explorer-Personal.png"
                            )}
                          />
                        </Link>
                      </div>
                      <a
                        href="https://app.advanxhealth.com/products"
                        className="btn btn-pink px-5 mt-3"
                      >
                        <strong>SHOP NOW</strong>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Slider>
        </div>
        <section id="about" className="bg-gradient-blue-green ">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 text-white" style={{ zIndex: "100" }}>
                <h3 className="text-white">
                  Your DNA is what makes you, <br />
                  <em>uniquely you</em>
                </h3>
                <div className="p-0 col-7">
                  <p className="mt-5">
                    The color of your eyes, your body's response to medicines,
                    how well your body takes in vitamins.{" "}
                  </p>
                  <p className="mt-5">
                    These are just some of the things that your DNA has an
                    effect on you. <strong>Your DNA makes you unique.</strong>
                  </p>
                </div>
              </div>
              <div className="col-6 px-0 offset-lg-0 offset-6" id="about-image">
                <img
                  className="img-fluid rounded-circle"
                  src={withPrefix("/img/humans/smiling-girl-denim-round.png")}
                  alt="Smiling Girl"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="cta" className="cta bg-white">
          <div className="container">
            <div className="row align-items-center my-5">
              <div className="col-lg-10 offset-lg-1 text-center">
                <h2 className="text-center">Explore Your DNA Today.</h2>
              </div>
            </div>
            <CTA />
          </div>
        </section>
        <section className="py-0" id="bigger-picture">
          <div className="bg-gray d-md-block d-none">
            <div className="row align-items-center">
              <div className="col-md-4">
                <img
                  src={withPrefix("/img/humans/family.png")}
                  className="img-fluid"
                  alt="family"
                />
              </div>
              <div className="col-md-8 container px-md-4">
                <header className="text-left mb-5">
                  <h2>Health is not one dimension.</h2>
                  <p>
                    To live smart and eat smarter, we will help you see the
                    bigger picture, in many dimensions.
                  </p>
                </header>
                <div className="row align-items-start">
                  <div className="col-md-4 text-center">
                    <img
                      src={withPrefix("/img/lp-icons/icon-dna.png")}
                      className="lp-icons mb-4"
                      height="70px"
                      alt="dna"
                    />
                    <h4 className="mb-4">Your DNA Is Just The Beginning</h4>
                    <p>
                      You are born with a unique genetic blueprint. We will
                      analyse nutrition-related genetic markers that are based
                      on research.
                    </p>
                  </div>
                  <div className="col-md-4 text-center">
                    <img
                      src={withPrefix("/img/lp-icons/icon-meal.png")}
                      className="lp-icons mb-4"
                      height="70px"
                      alt="lifestyle"
                    />
                    <h4 className="mb-4">
                      Your Lifestyle Unlocks Another Dimension
                    </h4>
                    <p>
                      Your body metrics, lifestyle, dietary preferences,
                      physical activities makes up a large part of the bigger
                      picture.{" "}
                    </p>
                  </div>
                  <div className="col-md-4 text-center">
                    <img
                      src={withPrefix("/img/lp-icons/icon-yoga.png")}
                      className="lp-icons mb-4"
                      height="70px"
                      alt="goals"
                    />
                    <h4 className="mb-4">
                      Your Health Goal Completes The Picture
                    </h4>
                    <p>
                      Without a health goal, is like driving without a
                      destination. Your health goal will serve as the main
                      navigator when you're eating smart.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-block d-md-none">
            <img
              src={withPrefix("/img/humans/family.png")}
              alt="family"
              style={{
                objectFit: "cover",
                opacity: "30%",
                position: "absolute",
                left: "0",
                top: "0",
                height: "100%",
                zIndex: "-1",
              }}
            />
            <div className="container">
              <header className="text-left my-3">
                <h4>Health is not one dimension.</h4>
                <p style={{ fontSize: "0.9rem", lineHeight: "1.2rem" }}>
                  To live smart and eat smarter, we will help you see the bigger
                  picture, in many dimensions.
                </p>
              </header>
              <div className="py-3 px-5 text-center">
                <img
                  src={withPrefix("/img/lp-icons/icon-dna.png")}
                  className="lp-icons mb-4"
                  height="70px"
                  alt="dna"
                />
                <h5 className="mb-4">Your DNA Is Just The Beginning</h5>
                <p style={{ fontSize: "0.9rem", lineHeight: "1.2rem" }}>
                  You are born with a unique genetic blueprint. We will analyse
                  nutrition-related genetic markers that are based on research.
                </p>
              </div>
              <div className="py-3 px-5 text-center">
                <img
                  src={withPrefix("/img/lp-icons/icon-meal.png")}
                  className="lp-icons mb-4"
                  height="70px"
                  alt="lifestyle"
                />
                <h5 className="mb-4">
                  Your Lifestyle Unlocks Another Dimension
                </h5>
                <p style={{ fontSize: "0.9rem", lineHeight: "1.2rem" }}>
                  Your body metrics, lifestyle, dietary preferences, physical
                  activities makes up a large part of the bigger picture.{" "}
                </p>
              </div>
              <div className="py-3 px-5 text-center">
                <img
                  src={withPrefix("/img/lp-icons/icon-yoga.png")}
                  className="lp-icons mb-4"
                  height="70px"
                  alt="goals"
                />
                <h5 className="mb-4">Your Health Goal Completes The Picture</h5>
                <p style={{ fontSize: "0.9rem", lineHeight: "1.2rem" }}>
                  Without a health goal, is like driving without a destination.
                  Your health goal will serve as the main navigator when you're
                  eating smart.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gradient-pink-orange">
          <Reviews />
        </section>
        <section id="traits" className="mb-0">
          <div className="container text-center">
            <header className="text-center no-margin-bottom">
              <h2>What Can Your DNA Tell You About Yourself</h2>
              <p className="lead">
                Decode your genes. Discover traits that can affect you, and
                learn how you can start eating smarter.
              </p>
            </header>
            <div className="row border rounded shadow py-5 mx-0">
              <div className="col-6 col-md-3 d-flex flex-column">
                <div
                  className="icon text-center"
                  style={{ padding: "2rem 0.5rem" }}
                >
                  <img
                    src={withPrefix("/img/illustrations/personal-allergy.png")}
                    className="lp-icons img-fluid"
                    alt="allergy"
                  />
                </div>
                <h5 className="text-center mb-3">Allergy & Sensitivity</h5>
                <p>
                  <span className="badge badge-primary-blue">14 traits</span>
                </p>
                <div className="d-none d-md-flex flex-column">
                  <span>Allergy to Cats</span>
                  <span>Allergy to Peanuts</span>
                  <span>Caffeine Metabolism</span>
                  <span>Hay Fever</span>
                  <span>Lactose Intolerance</span>
                  <span>Motion Sickness</span>
                  <Link
                    to={withPrefix("/reports/allergy-sensitivity")}
                    className="btn btn-sm mt-auto text font-weight-bold"
                  >
                    <u>and more</u>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column">
                <div
                  className="icon text-center"
                  style={{ padding: "2rem 0.5rem" }}
                >
                  <img
                    src={withPrefix("/img/illustrations/personal-fitness.svg")}
                    className="lp-icons img-fluid"
                    alt="fitness"
                  />
                </div>
                <h5 className="text-center mb-3">Fitness</h5>
                <p>
                  <span className="badge badge-primary-pink">21 traits</span>
                </p>
                <div className="d-none d-md-flex flex-column">
                  <span>Lactate Threshold</span>
                  <span>Joint Injury</span>
                  <span>Lean Body mass</span>
                  <span>Muscle Endurance</span>
                  <span>Muscle Power</span>
                  <span>Stress Fracture</span>
                  <Link
                    to={withPrefix("/reports/fitness")}
                    className="btn btn-sm mt-auto text font-weight-bold"
                  >
                    <u>and more</u>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column">
                <div
                  className="icon text-center"
                  style={{ padding: "2rem 0.5rem" }}
                >
                  <img
                    src={withPrefix(
                      "/img/illustrations/personal-healthrisk.png"
                    )}
                    className="lp-icons img-fluid"
                    alt="health"
                  />
                </div>
                <h5 className="text-center mb-3">Health Risks</h5>
                <p>
                  <span className="badge badge-primary-blue">31 traits</span>
                </p>
                <div className="d-none d-md-flex flex-column">
                  <span>Glaucoma</span>
                  <span>Inflammation</span>
                  <span>Lumbar Disc Disease</span>
                  <span>Nicotine Dependence</span>
                  <span>Parkinson's Disease</span>
                  <span>Obesity</span>
                  <Link
                    to={withPrefix("/reports/health")}
                    className="btn btn-sm mt-auto text font-weight-bold"
                  >
                    <u>and more</u>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column">
                <div
                  className="icon text-center"
                  style={{ padding: "2rem 0.5rem" }}
                >
                  <img
                    src={withPrefix(
                      "/img/illustrations/personal-nutrition.png"
                    )}
                    className="lp-icons img-fluid"
                    alt="fitness"
                  />
                </div>
                <h5 className="text-center mb-3">Nutrigenomics</h5>
                <p>
                  <span className="badge badge-primary-pink">11 traits</span>
                </p>
                <div className="d-none d-md-flex flex-column">
                  <span>Calcium Requirement</span>
                  <span>Iodine Requirement</span>
                  <span>Iron Requirement</span>
                  <span>Vitamin A Requirement</span>
                  <span>Vitamin C Requirement</span>
                  <span>Vitamin E Requirement</span>
                  <Link
                    to={withPrefix("/reports/nutrition")}
                    className="btn btn-sm mt-auto text font-weight-bold"
                  >
                    <u>and more</u>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column">
                <div
                  className="icon text-center"
                  style={{ padding: "2rem 0.5rem" }}
                >
                  <img
                    src={withPrefix(
                      "/img/illustrations/personal-innerpotential.png"
                    )}
                    className="lp-icons img-fluid"
                    alt="personality"
                  />
                </div>
                <h5 className="text-center mb-3">Personality</h5>
                <p>
                  <span className="badge badge-primary-pink">12 traits</span>
                </p>
                <div className="d-none d-md-flex flex-column">
                  <span>Deep Sleep</span>
                  <span>Language Development</span>
                  <span>Reading Ability</span>
                  <span>Alcohol Flush</span>
                  <span>Absolute Pitch</span>
                  <span>Creativity</span>
                  <Link
                    to={withPrefix("/reports/inner-potential")}
                    className="btn btn-sm mt-auto text font-weight-bold"
                  >
                    <u>and more</u>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column">
                <div
                  className="icon text-center"
                  style={{ padding: "2rem 0.5rem" }}
                >
                  <img
                    src={withPrefix("/img/illustrations/personal-skin.svg")}
                    className="lp-icons img-fluid"
                    alt="fitness"
                  />
                </div>
                <h5 className="text-center mb-3">Skin</h5>
                <p>
                  <span className="badge badge-primary-blue">15 traits</span>
                </p>
                <div className="d-none d-md-flex flex-column">
                  <span>Stretch Mark</span>
                  <span>Acne Vulgaris</span>
                  <span>Collagen Breakdown</span>
                  <span>Skin Aging</span>
                  <span>Sun Tanning</span>
                  <span>UV Protection</span>
                  <Link
                    to={withPrefix("/reports/skin")}
                    className="btn btn-sm mt-auto text font-weight-bold"
                  >
                    <u>and more</u>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column">
                <div
                  className="icon text-center"
                  style={{ padding: "2rem 0.5rem" }}
                >
                  <img
                    src={withPrefix(
                      "/img/illustrations/personal-weightmanagement.svg"
                    )}
                    className="lp-icons img-fluid"
                    alt="health"
                  />
                </div>
                <h5 className="text-center mb-3">Weight Management</h5>
                <p>
                  <span className="badge badge-primary-pink">17 traits</span>
                </p>
                <div className="d-none d-md-flex flex-column">
                  <span>Fat Storage</span>
                  <span>Metabolic Circadian Rhythm</span>
                  <span>Metabolism Rate</span>
                  <span>Overweight Potential</span>
                  <span>Satiety Regulation</span>
                  <span>Fat Sensitivity</span>
                  <Link
                    to={withPrefix("/reports/weight-management")}
                    className="btn btn-sm mt-auto text font-weight-bold"
                  >
                    <u>and more</u>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column">
                <div
                  className="icon text-center"
                  style={{ padding: "2rem 0.5rem" }}
                >
                  <img
                    src={withPrefix("/img/illustrations/personal-carrier.svg")}
                    className="lp-icons img-fluid"
                    alt="fitness"
                  />
                </div>
                <h5 className="text-center mb-3">Carrier Status</h5>
                <p>
                  <span className="badge badge-primary-blue">13 traits</span>
                </p>
                <div className="d-none d-md-flex flex-column">
                  <span>Beta thalassemia</span>
                  <span>G-6-PD</span>
                  <span>Phenylketonuria</span>
                  <span>Biotinidase deficiency</span>
                  <span>Sickle cell anemia</span>
                  <span>Tay-Sachs disease</span>
                  <Link
                    to={withPrefix("/reports/health")}
                    className="btn btn-sm mt-auto text font-weight-bold"
                  >
                    <u>and more</u>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="how-it-works bg-light py-5">
          <HowItWorks />
        </section>
        <section>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4 offset-md-1 text-left">
                <h2>What You'll Get</h2>
                <div className="my-4">
                  <p>
                    Get an even more comprehensive understanding on your health
                    risks, nutrition and fitness traits based on your DNA.
                  </p>
                  <p>
                    Get personalised recommendations on what you can do to
                    manage your lifestyle.
                  </p>
                </div>
                <a
                  href="/products/dna-explorer-prime"
                  className="btn btn-primary"
                  target="_blank"
                >
                  Learn More
                </a>
              </div>
              <div className="col-md-6 d-none d-md-block ">
                <img
                  src={withPrefix("/img/gifs/new-dashboard.gif")}
                  style={{ maxHeight: "80vh" }}
                  alt="sample report"
                />
              </div>
              <div className="col-10 mt-5 offset-2 d-block d-md-none ">
                <img
                  src={withPrefix("/img/gifs/new-dashboard.gif")}
                  style={{ maxHeight: "40vh" }}
                  alt="sample report"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="faq" className="bg-light py-5">
          <Faq />
        </section>
        <div
          id="mySidebar"
          className="sidebar"
          style={{ width: this.state.sidebarWidth }}
          onBlur={() => this.setState({ sidebarWidth: "0px" })}
        >
          <a className="closebtn" onClick={() => this.onSetSidebarClose(true)}>
            &times;
          </a>
          <div className="text-center m-2">
            <h2 className="pb-4">Spin to win discount code.</h2>
            <Wheel items={this.places} getPrize={this.getPrize} init={0} />
            <br />
            <OptInForm
              opt_in_form={opt_wheels_form}
              sidebar={true}
              code={
                this.state.codes[
                  this.state.prize === 2
                    ? this.state.prize - 1
                    : this.state.prize
                ]
              }
            />
          </div>
        </div>
        <div className="icon-bar">
          <a
            className="spin-wheel-button "
            onClick={() => this.onSetSidebarOpen(true)}
          >
            <span className="mb-3">Spin to win</span>
            <img src={"/img/spinning-wheels.svg"} alt="spinning wheel"></img>
          </a>
        </div>
        <OptInForm opt_in_form={opt_in_form} />
        {
          //<ModalBox />
        }
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "Advanx Health | Discover Your DNA, Personalise Your Lifestyle",
    description:
      "Discover your DNA with comprehensive DNA tests and get personalised recommendations for health risks, nutrition traits, fitness traits and more!",
    image: "https://www.advanxhealth.com/img/share/power-of-genes.png",
    url: "https://www.advanxhealth.com",
  },
  opt_in_form: {
    header: "Subscribe to Our Newsletter",
    description: "Stay updated with promotions, updates, health tips and more!",
    form_name: "home-newsletter",
    form_action: "/",
    form_interest: "newsletter",
    form_submit: "Subscribe",
    form_funnel_id: "9841248",
    mailchimp_group_id: "db8b07b00c",
    mailchimp_group_list_id: "14c5755ee7",
    mailchimp_tag_id: "27845",
  },
  opt_wheels_form: {
    form_name: "spinning-wheels-subscribers",
    form_action: "/",
    form_interest: "test",
    form_submit: "Send my code",
    form_funnel_id: "9841248",
    mailchimp_group_id: "db8b07b00c",
    mailchimp_group_list_id: "14c5755ee7",
    mailchimp_tag_id: "27845",
  },
}
